
  import Vue from 'vue';
  import CountryFlag from '../CountryFlag/CountryFlag.vue';
  import WaveDivider from '../WaveDivider/WaveDivider.vue';
  
  export default Vue.extend({
    name: 'HeaderSection',
    props: {
        title: String,
        subtitle: String
    },
    components: {
        CountryFlag,
    }
  });
  