
import WaveDivider from '@/components/WaveDivider/WaveDivider.vue'
import Vue, {PropType} from 'vue';
  
  export default Vue.extend({
    name: 'AboutSection',
    props: {
        title: String,
        paragraphs: Array as PropType<string[]>
    },
    computed: {
        basePath() {
            return process.env.BASE_URL;
        }
    },
    components: {
        WaveDivider
    }
  });
  