
  import Vue, { PropType } from 'vue';
  import Card from '../Card/Card.vue';
  import IButton from '../Shared/interfaces/IButton'
  import MaterialButton from '../MaterialButton/MaterialButton.vue'
  
  export default Vue.extend({
    name: 'WorkExperience',
    props: {
        title: String,
        workTopics: Array as PropType<string[]>,
        toolbarButton: Object as PropType<IButton>
    },
    components: {
        Card,
        MaterialButton
    }
  });
  