
import Vue from 'vue';
import GenericNavbar from '../GenericNavbar/GenericNavbar.vue';
import NavColorEnum from './enum/NavColorEnum';
  
  export default Vue.extend({
  components: { GenericNavbar },
    name: 'FooterBar',
    props: {
      email: String,
    },
    computed: {
        websiteVersion: function() : string {
            return process.env.VUE_APP_VERSION || '0.0.0'
        },
        githubUrl: function() : string {
            return `https://github.com/rafaelfaustini/rafaelfaustini.com.br/releases/tag/${this.websiteVersion}`
        },
        websiteVersionText: function() : string {
            return `v${this.websiteVersion}`;
        }
    },
    data() {
        return {
            navbarColor: NavColorEnum.Primary
        }
    }
  });

  