
  import Vue, { PropType } from 'vue';
  import MaterialButton from '../MaterialButton/MaterialButton.vue';
  import IButton from '../Shared/interfaces/IButton';
  import WorkExperience from '../WorkExperience/WorkExperience.vue';
  import IWorkExperience from './interfaces/IWorkExperience';
  
  export default Vue.extend({
    name: 'ExperienceSection',
    props: {
        title: String,
        currentWorks: Array as PropType<IWorkExperience[]>,
        pastWorks: Array as PropType<IWorkExperience[]>,
        resumeButtonOption: Object as PropType<IButton>
    },
    components: {
        WorkExperience,
        MaterialButton
    }
  });
  