
import Vue from 'vue';
import HeaderSection from './components/HeaderSection/HeaderSection.vue';
import AboutSection from './components/AboutSection/AboutSection.vue';
import ExperienceSection from './components/ExperienceSection/ExperienceSection.vue';
import WaveDivider from './components/WaveDivider/WaveDivider.vue'
import HeaderBar from './components/HeaderBar/HeaderBar.vue';
import FooterBar from './components/FooterBar/FooterBar.vue';
import PortfolioSection from './components/PortfolioSection/PortfolioSection.vue';
import IAboutSection from './components/Shared/interfaces/IAboutSection';
import IHeaderSection from './components/Shared/interfaces/IHeaderSection';
import IHeaderBar from './components/Shared/interfaces/IHeaderBar';

import {createHeaderBar, createHeaderSection, createAboutSection, createExperienceSection, createPortfolioSection} from './TranslationValues'
import i18next from 'i18next';

export default Vue.extend({
  name: 'App',
  
  components: {
    HeaderSection,
    AboutSection,
    ExperienceSection,
    HeaderBar,
    FooterBar,
    PortfolioSection
  },
  data() {
    return {
     headerBar: createHeaderBar(this.$t) as IHeaderBar,
     headerSection : createHeaderSection(this.$t) as IHeaderSection,
     aboutSection : createAboutSection(this.$t) as IAboutSection,
     experienceSection : createExperienceSection(this.$t),
     portifolioSection : createPortfolioSection(this.$t)
    }
  },
  mounted() {
    this.$nextTick(() => {
      i18next.on('languageChanged', () => {
        this.reloadTexts();
      });
    });
  },
  methods: {
    reloadTexts() {
     this.headerBar = createHeaderBar(this.$t) as IHeaderBar,
     this.headerSection = createHeaderSection(this.$t) as IHeaderSection,
     this.aboutSection = createAboutSection(this.$t) as IAboutSection,
     this.experienceSection = createExperienceSection(this.$t),
     this.portifolioSection = createPortfolioSection(this.$t)
    }
  },
});
