
import Vue, { PropType } from 'vue';
import ButtonColorEnum from '../MaterialButton/enum/ButtonColorEnum';
import MaterialButton from '../MaterialButton/MaterialButton.vue';
import IButton from '../Shared/interfaces/IButton';
import IProjectImage from './interface/IProjectImage';
import PlaceholderImage from '../PlaceholderImage/PlaceholderImage.vue';

export default Vue.extend({
  name: 'Project',
  props: {
      title: String,
      year: Number,
      language: String,
      tags: Array as PropType<string[]>,
      description: String,
      actionButton: Object as PropType<IButton>,
      sourceCodeButton: Object as PropType<IButton>,
      projectImage: Object as PropType<IProjectImage> 
  },
  data() {
    return {
        actionButtonColor: ButtonColorEnum.Secondary,
        sourceCodeButtonColor: ButtonColorEnum.SecondaryOutline
    }
  },
  computed: {
    basePath() {
        return process.env.BASE_URL;
    }
  },
  components: {
    MaterialButton,
    PlaceholderImage
  }
});
