
  import i18next from 'i18next';
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'LanguageSwitcher',
    data() {
        return {
            currentLanguage: i18next.language,
            isDropdownOpen: false
        }
    },
    methods: {
        changeLanguage(language : string) {
            this.currentLanguage = language;
            this.$i18next.changeLanguage(language);
            this.isDropdownOpen = false;
            
        },
        handleClick(el: any): void {
            if(!this.isDropdownOpen) {
                return;
            }
        }
    },
  });
  