
import Vue, { PropType } from 'vue';
import IButton from '../Shared/interfaces/IButton';
import MaterialButton from '../MaterialButton/MaterialButton.vue';
import Projects from '../Projects/Projects.vue';
import IProject from '../Projects/interface/IProject';
import WaveDivider from '../WaveDivider/WaveDivider.vue';

export default Vue.extend({
  name: 'PortfolioSection',
  props: {
    title: String,
    searchPlaceholder: String,
    projects: Array as PropType<IProject[]>,
    githubButton: Object as PropType<IButton>
  },
  components: {
    MaterialButton,
    Projects,
    WaveDivider
  }
});
