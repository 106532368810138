
  import Vue from 'vue';
  import { PropType } from 'vue/types/v3-component-props';
  import INavMenuItem from './interfaces/INavMenuItem';
  
  export default Vue.extend({
    name: 'NavMenu',
    props: {
      itemList: Array as PropType<INavMenuItem[]>,
    },
    data() {
    return {
      activeNav: "#",
    }
  },
    mounted() {
      let sections = document.querySelectorAll('section')
      window.onscroll = () => {
        sections.forEach(section => {
          let top = window.scrollY;
          let offset = section.offsetTop;
          let height = section.offsetHeight;
          let id = section.getAttribute('id');

          let href = id == 'header' ? "#" : `#${id}`;

          if(top >= offset && top < offset + height) {
            this.activeNav = href || '#'
            console.log(this.activeNav)
          }
        });
      }
    }
  });
  