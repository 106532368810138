
  import Vue, { PropType } from 'vue';
  import CountryFlagEnum from './enum/CountryFlagEnum';
  
  export default Vue.extend({
    name: 'CountryFlag',
    props: {
        defaultFlag: Number as PropType<CountryFlagEnum>,
        slideAnimation: Boolean,
        slideInterval: Number
    },
    data() {
      return {
        currentFlag: CountryFlagEnum.Brasil as CountryFlagEnum,
      }
    },
    computed: {
      showBrasilFlag: function() : boolean {
        return this.currentFlag == CountryFlagEnum.Brasil;
      },
      showItaliaFlag: function() : boolean {
        return this.currentFlag == CountryFlagEnum.Italia;
      },
      showPortugalFlag: function() : boolean {
        return this.currentFlag == CountryFlagEnum.Portugal;
      },
    },
    mounted() {
      this.currentFlag = this.defaultFlag || CountryFlagEnum.Brasil;

      if(this.slideAnimation) {
        setInterval(this.updateFlag, this.slideInterval * 1000)
      }
    },

    methods: {
      updateFlag() {
        let totalFlags = Object.keys(CountryFlagEnum).length  / 2; 
        this.currentFlag = ((this.currentFlag + 1) % totalFlags);
      }
    },
  });
  