
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'MaterialInput',
    props: {
        placeholder: String
    },
    computed: {

    },
    methods: {
        updateValue(event:any) {
            this.$emit('input', event.target.value)
        },
        focusSearch() {
            const searchBar : any = this.$refs.projectsSearch;
            searchBar.focus();
            
        }
    },
    components: {
        
    }
  });
  