
import Vue, { PropType } from 'vue';
import GenericNavbar from '../GenericNavbar/GenericNavbar.vue';
import INavMenuItem from '../NavMenu/interfaces/INavMenuItem';
import NavColorEnum from './enum/NavColorEnum';
import NavMenu from '../NavMenu/NavMenu.vue';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher.vue';
  
  export default Vue.extend({
  components: { GenericNavbar, NavMenu, LanguageSwitcher },
    name: 'HeaderBar',
    props: {
      logoName: String,
      navItemList: Array as PropType<INavMenuItem[]>
    },
    computed: {
        EnvironmentBadgeText() {
            switch (process.env.VUE_APP_ENV) {
                default:
                case "development":
                case "beta":
                    return process.env.VUE_APP_ENV_SHORT_NAME
            }
        }
    },
    data() {
        return {
            navbarColor: NavColorEnum.Default
        }
    },
    methods: {
        getCurrentEnv() {
            return process.env.VUE_APP_ENV;
        }
    },
  });

  