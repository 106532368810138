
  import Vue, { PropType } from 'vue';
  import NavColorEnum from './enum/NavColorEnum';
  
  export default Vue.extend({
    name: 'GenericNavbar',
    props: {
      color: String as PropType<NavColorEnum>
    },
    computed: {
        isPrimaryColor: function() : boolean {
            return this.color == NavColorEnum.Primary
        },
        isSecondaryColor: function() : boolean {
            return this.color == NavColorEnum.Secondary
        },
    },
  });

  