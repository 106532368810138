
  import Vue, { PropType } from 'vue';
  import ButtonSizeEnum from './enum/ButtonSizeEnum';
  import ButtonColorEnum from './enum/ButtonColorEnum';
  
  export default Vue.extend({
    name: 'MaterialButton',
    props: {
        text: String,
        color: String as PropType<ButtonColorEnum>,
        size: String as PropType<ButtonSizeEnum>,
        href: String,
        onClick: Function,
    },
    computed: {
        isPrimary: function() : boolean {
            return this.color == ButtonColorEnum.Primary;
        },
        isPrimaryOutline: function() : boolean {
            return this.color == ButtonColorEnum.PrimaryOutline;
        },
        isSecondary: function() : boolean {
            return this.color == ButtonColorEnum.Secondary;
        },
        isSecondaryOutline: function() : boolean {
            return this.color == ButtonColorEnum.SecondaryOutline;
        },
        isLargeSize: function() : boolean {
            return this.size == ButtonSizeEnum.Large;
        }
    },
    methods: {
        executeButtonAction() {
            if(this.onClick) {
                this.onClick;
            }
            if(this.href) {
                window.open(this.href, '_blank');
            }
        }
    },
    components: {
        
    }
  });
  