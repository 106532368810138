
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'WaveDivider',
    props: {
        color: String,
        flipDown: Boolean,
    },
    components: {
    }
  });
  